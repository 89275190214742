.side-panel-login {
    float: none;
    width: 30vw;
    top: 25vh;
    right: 4vw;
    position: fixed;
    background-color: white;
}

.filler {
    flex: 3;
}

.banner-container {
    width: 60vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 4vw;
}

.banner-container > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.banner {
    width: 35vw;
}

.mockup-phone {
    height: 60vh;
}

.tagline {
    font-size: x-large;
    margin-bottom: 20px;
}

.available-on-label {
    align-self: flex-start;
    margin: 20px;
    font-size: small;
    margin-bottom: 0;
}

.bg {
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    border-top: 80vw solid #00b1b6;
    border-right: 40vw solid transparent;
    border-bottom: 80vw solid transparent;
}

.google-playstore {
    height: 10vh;
}

.apple-appstore {
    height: 6.8vh;
}

.store-badge-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.forgot-password-link {
    color: #00b1b6;
    font-weight: bold;
}

.side-panel-login-bottom {
    align-items: center;
}

.side-panel-login-bottom > button {
    flex: 0;
}

@media (max-width: 1024px) {
    .side-panel-login {
        width: 40vw;
        top: 20vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .banner-container {
        width: 80vw;
        margin-left: 2vw;
    }

    .mockup-phone {
        height: 50vh;
    }
}

@media (max-width: 768px) {
    .side-panel-login {
        width: 50vw;
        top: 15vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .banner {
        display: none;
    }

    .banner-container {
        width: 90vw;
        flex-direction: column;
        margin-left: 1vw;
    }

    .mockup-phone {
        height: 40vh;
    }
}

@media (max-width: 480px) {
    .side-panel-login {
        width: 80vw;
        top: 10vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .banner {
        display: none;
    }

    .banner-container {
        width: 100vw;
        margin-left: 0;
    }

    .mockup-phone {
        height: 30vh;
    }

    .tagline {
        font-size: large;
    }

    .available-on-label {
        font-size: smaller;
    }
}
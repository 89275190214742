.regular-th {
    text-align: left;
    padding: 10px;
    color: white;
}

.filter-th {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0.5px solid #dddddd;
    background-color: white;
}

.filter-input {
    width: 90%;
    border: 0;
}

.autotable-container {
    width: 100%;
    height: 74vh;
    overflow: auto;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1920px) {
    .autotable-container {
        height: 78vh;
    }
}

.autotable-header-table {
    width: 100%;
    height: auto;
    border-spacing: 0;
}

.autotable-header-table > thead {
    position: sticky;
    top: 0;
}

.tr-header {
    background-color: #00b1b6;
    border: 0.5px solid #009499;
}

.tr-filter > th {
    border-right: 0;
}

.tr-filter > th:last-child {
    border-right: 0.5px solid #dddddd;
}

@keyframes autotable-tr-hover {
    from { background-color: white; }
    to { background-color: #f0f0f0; }
}

.autotable-tr {
    border: 0;
    background-color: white;
    border-collapse: collapse;
    cursor: pointer;
}
  
.autotable-tr:hover {
    background-color: #f0f0f0; 
    animation-name: autotable-tr-hover;
    animation-duration: 0.5s;
}

.autotable-tr > td:first-child {
    border-left: 0.5px solid #dddddd;
}

.autotable-tr > td:last-child {
    border-right: 0.5px solid #dddddd;
}

.autotable-td {
    border-bottom: 0.5px solid #dddddd;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}
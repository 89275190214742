.map {
    width: 100%;
    height: 350px;
}

.panel-horizontal {
    background-color: transparent;
    /* position: sticky; */
    top: 0;
}

.listing {
    position: sticky;
    top: 0;
}

.listing-search-map {
    flex: 1;
}

.search-map {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}

.map-dialog {
    width: 35vw;
    height: 60vh;
    top: 10vh;
    left: 10vw;
    position: fixed;
    z-index: 3;
    display: none;
}

.map-dialog-show {
    display: flex;
}

.location-side-panel {
    z-index: 3;
    position: relative;
}

.radius-slider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

.radius-toggle {
    margin-left: 2vw;
}
.device-dialog-body {
    flex-direction: row;
    align-items: flex-start;
}

.device-list {
    flex: 1;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    border-right: 0.5px solid #dddddd;
}

.device-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow: auto;
}